import {Col, Container, Row} from "react-bootstrap";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import styles from "components/ProductGrid.module.scss";
import ProductListCard from "components/cards/ProductListCard";


const ProductGrid = (props) => {
    const {products, showRank, referralInfo, vertical, miniScrapIcon} = props;
    const onProductClick = props.onProductClick || function () {
    };

    return (
        <Container>
            <div className={utilStyles.flexRow}>
                <Row className={classNames(utilStyles.fullFlex, styles.row)}>
                    {
                        products.map((product, idx) => {
                            return (
                                <Col key={product.id} xs={6} lg={6} xl={6}
                                     className={styles.col}>
                                    <ProductListCard product={product} showRank={showRank} referralInfo={referralInfo}
                                                     onClick={() => onProductClick(product, idx)}
                                                     textPaddingType="medium"
                                                     miniScrapIcon={miniScrapIcon}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </Container>
    );
};

export default ProductGrid;
