import {TabSelector} from "pages/mypage/faq";
import CategoryContext from "context/CategoryContext";
import styles from "components/layout/NavBar/storeNavBar.module.scss";
import {useContext} from "react";
import classNames from "classnames";
import {storeRoute} from "common/const";
import Link from "next/link";
import {useRouter} from "next/router";
import utilStyles from "styles/utils.module.scss";
import ScrollContext from "context/ScrollContext";
import {KEY_RANK} from "pages/store/products";

const cell = (x, cl, key, row, col) => {
    const data = x ? Object.values(x)[0] : null;
    const router = useRouter();

    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('StoreNavBarSubcategoryTabClick', {category: data.name, categoryId: data.id, row, col})
    };

    return (
        data ?
            <Link
                key={key}
                replace={true}
                href={{
                    query: router.query.category === data.query_string ? router.query : Object.assign({}, router.query, {
                        category: data.query_string, page: 1,
                    }),
                }} shallow={false} scroll={false} >
                <a
                    className={classNames(styles.cell, cl)}
                    style={{height: 44}}
                    onClick={onClick}
                    // key={key}
                >
                    {data.name}
                </a>
            </Link>
            :
            <span
                className={classNames(styles.cell, cl)}
                style={{height: 44}}
                key={key}
            />
    )
}

export default function StoreNavBar(props) {
    const {stickyTop} = props;
    const categoryContext = useContext(CategoryContext);
    const {shouldHideNavBar} = useContext(ScrollContext);
    const router = useRouter();
    const {changeQueryParamOnly, hideSubCategory, noSubCategoryMarginBottom, maintainScroll, replace} = props;
    const categories = categoryContext.productCategories;
    const currentQueryString = router.query.category;
    const category = categoryContext.getMidCategory(currentQueryString);
    let children = category ? category.children : [];
    if (category && category.query_string) {
        children = children.concat([{
            id: category.id, parent_id: category.id, name: '전체', children: [], rank: -1,
            query_string: category.query_string,
        }]);
        children.sort((a, b) => (a.rank - b.rank));
    }
    const options = children.map(x => {
        return ({[x.query_string]: x})
    });

    const onTabClick = (x, tabIndex) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('StoreNavBarCategoryTabClick', {category: x.name, categoryId: x.id, tabIndex})
    };

    return (
        <>
            <div className={classNames(styles.midCategoryWrapper, shouldHideNavBar && utilStyles.hide)} style={{top: stickyTop}}>
                {
                    categories.map((x, index) => {
                        return (
                            <Link
                                replace={replace}
                                key={`mid_${x.id}`}
                                href={{
                                    query: router.query.category === x.query_string ? router.query : Object.assign({}, router.query, {
                                        category: x.query_string, page: 1,
                                    }),
                                }} shallow={false} scroll={!Boolean(maintainScroll)}>
                                <a
                                    className={classNames(styles.midCategory, category && category.id === x.id && styles.activeCategory)}
                                    key={x.id}
                                    onClick={() => {onTabClick(x, index)}}
                                >
                                    {x.name}
                                </a>
                            </Link>
                        )
                    })
                }
            </div>
            {/*<div style={{height: utilStyles.mobilStoreNavBarHeight}}/>*/}
            {
                !hideSubCategory && options.length > 0
                    ?
                    <>
                        <TabSelector
                            colSize={3} options={options} noRadius fontSize={13}
                            isActiveTabDark
                            noHighlightActiveTab
                            value={currentQueryString}
                            customCell={cell}
                            lightBorder
                            noSideBorder
                        />
                        {!noSubCategoryMarginBottom && <div style={{height: 24}} />}
                    </>
                    :
                    <div className={styles.border}/>
            }
        </>
    )
}
